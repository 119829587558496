import React, { useContext } from 'react'
import styled, { css } from 'styled-components'
import { theme } from '../../../utils/theme'
import { useTranslation } from 'react-i18next-new'
import { Link, matchPath, NavLink } from 'react-router-dom'
import { ProfilePicture } from '../../reusable_components/other/CourseComponents'
import {
	LeftAlignedFlexBox,
	PageSectionRepulsiveRow,
	SmallPaddingWrapper,
	SmallPageSection,
	SmallPageSectionDivider,
	SmallPageSectionRepulsiveRow,
} from '../../reusable_components/layout/PageComponents'
import useRoutes from '../../../hooks/useRoutes'
import Impersonation from './Impersonation'
import { logOut } from '../../../utils/helpScoutHelper'
import Close from '../../reusable_components/svg_icons/Close'
import { P2, TextSpan } from '../../reusable_components/Typography'
import { StyledSmallHostSection } from '../../reusable_components/other/HostSections'
import Edit from '../../reusable_components/svg_icons/Edit'
import CrocodileRight from '../../reusable_components/svg_icons/CrocodileRight'
import Button from '../../reusable_components/buttons/Button'
import ToggleableElement from '../../reusable_components/other/ToggleableElement'
import { ClickableRow } from '../../admin/account/tickets/cancel_or_reschedule/CancelOrReschedulePage'
// import In from '../../reusable_components/svg_icons/In'
import Out from '../../reusable_components/svg_icons/Out'
import LanguageSwitcher from '../footer/LanguageSwitcher'
import UserContext from '../../../contexts/user/UserContext'
import ToolTip from '../../reusable_components/other/ToolTip'
import Diamond from '../../reusable_components/svg_icons/Diamond'
import useLocation from '../../../hooks/useLocation'
import NewFeatureIndicator from '../../reusable_components/other/NewFeatureIndicator'
import PwaButton from '../../reusable_components/buttons/PwaButton'
import CrocodileUp from '../../reusable_components/svg_icons/CrocodileUp'
import CrocodileDown from '../../reusable_components/svg_icons/CrocodileDown'

const SubOptions = styled.div`
	padding-left: ${theme.paddings.small};
	> div:not(:first-child) {
		padding-top: ${theme.paddings.XXSmall};
	}
`
const Wrapper = styled(SmallPaddingWrapper)`
	margin-bottom: ${theme.margins.XLarge};
	${({ adminSideBar }) =>
		adminSideBar &&
		css`
			${SmallPageSectionDivider}, [data-hide-from-admin-menu] {
				display: none;
			}
			padding-left: 0;
			padding-right: 0;
		`}
`
const StyledLink = styled(NavLink)`
	&.active {
		font-weight: bold;
	}
`

const UserMenuContent = ({ user, closeMenuOnLinkClick, adminSideBar, toolsHeader }) => {
	const routes = useRoutes()
	const location = useLocation()
	const { t, i18n } = useTranslation()
	const { userState, dispatch } = useContext(UserContext)
	// Let the host update their details in tools admin instead since they have more options there
	const editUserPath = user.isHost ? routes.admin.host.organizer.path : routes.admin.account.personalDetails.path
	return (
		<Wrapper adminSideBar={adminSideBar}>
			<SmallPageSectionRepulsiveRow>
				<P2>{t('shared:userMenu.myAccount')}</P2>
				{adminSideBar ? <div /> : <Close onClick={closeMenuOnLinkClick} />}
			</SmallPageSectionRepulsiveRow>
			<SmallPageSection>
				<StyledSmallHostSection>
					<div>
						<Link onClick={closeMenuOnLinkClick} to={editUserPath}>
							<ProfilePicture profileName={user.fullName} imagePath={user.image} />
						</Link>
					</div>
					<div>
						<p>
							{user.fullName}
							<br />
							<TextSpan textColor="lightTextColor">
								<Link onClick={closeMenuOnLinkClick} to={editUserPath}>
									{t('shared:userMenu.editProfile')}
								</Link>
							</TextSpan>
							&nbsp;&nbsp;
							{!user.image && (
								<Link onClick={closeMenuOnLinkClick} to={editUserPath}>
									<Edit width={12} height={12} />
								</Link>
							)}
						</p>
					</div>
				</StyledSmallHostSection>
			</SmallPageSection>
			{user.impersonated && (
				<>
					<Impersonation />
					<SmallPageSectionDivider />
				</>
			)}
			{user.isSuperAdmin && (
				<>
					<a href={routes.superAdmin.dashboard.url}>
						<SmallPageSectionRepulsiveRow>
							Super Admin
							<CrocodileRight />
						</SmallPageSectionRepulsiveRow>
					</a>
					<SmallPageSectionDivider />
				</>
			)}
			{toolsHeader ? (
				<>
					<Link onClick={closeMenuOnLinkClick} to={routes.admin.host.courseDates.path}>
						<SmallPageSectionRepulsiveRow>
							{t('shared:userMenu.addNew')}
							<CrocodileRight />
						</SmallPageSectionRepulsiveRow>
					</Link>
					<SmallPageSectionDivider />
					<StyledLink onClick={closeMenuOnLinkClick} to={routes.admin.host.overview.path}>
						<SmallPageSectionRepulsiveRow>
							<div>{t('admin:overviewPage.pageTitle')}</div>
							<CrocodileRight />
						</SmallPageSectionRepulsiveRow>
					</StyledLink>
					<SmallPageSectionDivider />
					<StyledLink onClick={closeMenuOnLinkClick} to={routes.admin.host.courses.path}>
						<SmallPageSectionRepulsiveRow>
							{t('admin:coursesPage.pageTitle')}
							<CrocodileRight />
						</SmallPageSectionRepulsiveRow>
					</StyledLink>
					<SmallPageSectionDivider />
					<StyledLink onClick={closeMenuOnLinkClick} to={routes.admin.host.courseDates.path}>
						<SmallPageSectionRepulsiveRow>
							{t('admin:courseDatesPage.pageTitle')}
							<CrocodileRight />
						</SmallPageSectionRepulsiveRow>
					</StyledLink>
					<SmallPageSectionDivider />
					<StyledLink
						onClick={() => {
							closeMenuOnLinkClick && closeMenuOnLinkClick()
							dispatch({
								type: 'NEW_FEATURE_DISCOVERED',
								data: 'EDIT_HOST_PAGE',
							})
						}}
						to={routes.admin.host.hostPage.path}
					>
						<SmallPageSectionRepulsiveRow>
							<p>{t('admin:companyPage.pageTitle')}</p>
							<CrocodileRight />
						</SmallPageSectionRepulsiveRow>
					</StyledLink>
					<SmallPageSectionDivider />
					<StyledLink
						onClick={() => {
							closeMenuOnLinkClick && closeMenuOnLinkClick()
							dispatch({
								type: 'NEW_FEATURE_DISCOVERED',
								data: 'PROMOTIONS_PAGE',
							})
						}}
						to={routes.admin.host.promotions.index.path}
					>
						<SmallPageSectionRepulsiveRow>
							<LeftAlignedFlexBox>
								<p>{t('admin:promotionsPage.pageTitle')}</p>
								<ToolTip name="promotionsDiamond" icon={<Diamond width={16} height={16} />}>
									{t('admin:upgradeTier.requiresStandard')}
								</ToolTip>
							</LeftAlignedFlexBox>
							<CrocodileRight />
						</SmallPageSectionRepulsiveRow>
					</StyledLink>
					<SmallPageSectionDivider />
					<StyledLink
						onClick={() => {
							closeMenuOnLinkClick && closeMenuOnLinkClick()
							dispatch({
								type: 'NEW_FEATURE_DISCOVERED',
								data: 'PARTICIPANTS_PAGE',
							})
						}}
						to={routes.admin.host.participants.index.path}
					>
						<SmallPageSectionRepulsiveRow>
							<LeftAlignedFlexBox>
								<p>{t('admin:participantsPage.pageTitle')}</p>
								<ToolTip name="participantsDiamond" icon={<Diamond width={16} height={16} />}>
									{t('admin:upgradeTier.requiresStandard')}
								</ToolTip>
							</LeftAlignedFlexBox>
							<CrocodileRight />
						</SmallPageSectionRepulsiveRow>
					</StyledLink>
					<SmallPageSectionDivider />
					<ToggleableElement
						toggler={toggled => (
							<ClickableRow toggled={toggled}>
								{matchPath(location.pathname, { path: `/${i18n.language}/admin/host/paid` }) ? (
									<b>{t('shared:userMenu.payments')}</b>
								) : (
									<p>{t('shared:userMenu.payments')}</p>
								)}
								<CrocodileRight />
							</ClickableRow>
						)}
					>
						<SubOptions>
							<div>
								<StyledLink onClick={closeMenuOnLinkClick} to={routes.admin.host.paid.charges.path}>
									{t('admin:chargesPage.pageTitle')}
								</StyledLink>
							</div>
							<div>
								<StyledLink onClick={closeMenuOnLinkClick} to={routes.admin.host.paid.payouts.path}>
									{t('admin:payoutsPage.pageTitle')}
								</StyledLink>
							</div>
							<div>
								<StyledLink onClick={closeMenuOnLinkClick} to={routes.admin.host.paid.info.path}>
									{t('admin:paymentDetailsPage.pageTitle')}
								</StyledLink>
							</div>
							<div>
								<StyledLink
									onClick={() => {
										closeMenuOnLinkClick && closeMenuOnLinkClick()
										dispatch({
											type: 'NEW_FEATURE_DISCOVERED',
											data: 'PAYMENT_METHODS_PAGE',
										})
									}}
									to={routes.admin.host.paid.methods.path}
								>
									{t('admin:methodsPage.pageTitle')}
									<NewFeatureIndicator featureId="PAYMENT_METHODS_PAGE" skipServerUpdate={adminSideBar} />
								</StyledLink>
							</div>
							<ToggleableElement
								toggler={toggled => (
									<LeftAlignedFlexBox>
										{matchPath(location.pathname, { path: `/${i18n.language}/admin/host/paid/tax` }) ? (
											<b>{t('shared:userMenu.tax')}</b>
										) : (
											<p>{t('shared:userMenu.tax')}</p>
										)}
										{toggled ? <CrocodileUp /> : <CrocodileDown />}
									</LeftAlignedFlexBox>
								)}
							>
								<SubOptions style={{ paddingTop: 0 }}>
									<div>
										<StyledLink onClick={closeMenuOnLinkClick} to={routes.admin.host.paid.tax.documents.path}>
											{t('admin:taxDocumentsPage.pageTitle')}
										</StyledLink>
									</div>
									<div>
										<StyledLink onClick={closeMenuOnLinkClick} to={routes.admin.host.paid.tax.settings.path}>
											{t('admin:taxSettingsPage.pageTitle')}
										</StyledLink>
									</div>
									<div>
										<StyledLink onClick={closeMenuOnLinkClick} to={routes.admin.host.paid.tax.registrations.path}>
											{t('admin:taxRegistrationsPage.pageTitle')}
										</StyledLink>
									</div>
								</SubOptions>
							</ToggleableElement>
						</SubOptions>
					</ToggleableElement>
					<SmallPageSectionDivider />
					<StyledLink
						onClick={() => {
							closeMenuOnLinkClick && closeMenuOnLinkClick()
							dispatch({
								type: 'NEW_FEATURE_DISCOVERED',
								data: 'REFERRALS_PAGE',
							})
						}}
						to={routes.admin.host.referrals.path}
					>
						<SmallPageSectionRepulsiveRow>
							<div>{t('admin:referralsPage.pageTitle')}</div>
							<CrocodileRight />
						</SmallPageSectionRepulsiveRow>
					</StyledLink>
					<SmallPageSectionDivider />
					<ToggleableElement
						toggler={toggled => (
							<ClickableRow toggled={toggled}>
								{matchPath(location.pathname, { path: `/${i18n.language}/admin/host/details` }) ? (
									<b>{t('shared:userMenu.userAccount')}</b>
								) : (
									<p>{t('shared:userMenu.userAccount')}</p>
								)}
								<CrocodileRight />
							</ClickableRow>
						)}
					>
						<SubOptions>
							<div>
								<StyledLink onClick={closeMenuOnLinkClick} to={routes.admin.host.organizer.path}>
									{t('admin:organizerPage.pageTitle')}
								</StyledLink>
							</div>
							<div>
								<StyledLink onClick={closeMenuOnLinkClick} to={routes.admin.host.dac7.path}>
									{t('admin:dac7Page.pageTitle')}
								</StyledLink>
							</div>
							<div>
								<StyledLink onClick={closeMenuOnLinkClick} to={routes.admin.host.password.path}>
									{t('admin:passwordPage.pageTitle')}
								</StyledLink>
							</div>
						</SubOptions>
					</ToggleableElement>
					<SmallPageSectionDivider />
					<StyledLink onClick={closeMenuOnLinkClick} to={routes.admin.host.progress.path}>
						<SmallPageSectionRepulsiveRow>
							{t('admin:progressPage.pageTitle')}
							<CrocodileRight />
						</SmallPageSectionRepulsiveRow>
					</StyledLink>
				</>
			) : (
				<>
					<StyledLink onClick={closeMenuOnLinkClick} to={routes.admin.account.tickets.path}>
						<SmallPageSectionRepulsiveRow>
							{t('admin:ticketsPage.pageTitle')}
							<CrocodileRight />
						</SmallPageSectionRepulsiveRow>
					</StyledLink>
					<SmallPageSectionDivider />
					<StyledLink onClick={closeMenuOnLinkClick} to={routes.admin.account.favourites.path}>
						<SmallPageSectionRepulsiveRow>
							{t('admin:favouritesPage.pageTitle')}
							<CrocodileRight />
						</SmallPageSectionRepulsiveRow>
					</StyledLink>
					<SmallPageSectionDivider />
					<StyledLink onClick={closeMenuOnLinkClick} to={editUserPath}>
						<SmallPageSectionRepulsiveRow>
							{t('admin:personalDetailsPage.pageTitle')}
							<CrocodileRight />
						</SmallPageSectionRepulsiveRow>
					</StyledLink>
					<SmallPageSectionDivider />
					<StyledLink onClick={closeMenuOnLinkClick} to={routes.admin.account.password.path}>
						<SmallPageSectionRepulsiveRow>
							{t('admin:passwordPage.pageTitle')}
							<CrocodileRight />
						</SmallPageSectionRepulsiveRow>
					</StyledLink>
				</>
			)}
			<SmallPageSectionDivider />
			<StyledLink
				onClick={closeMenuOnLinkClick}
				to={toolsHeader ? routes.admin.host.support.path : routes.admin.account.support.path}
			>
				<SmallPageSectionRepulsiveRow>
					{t('admin:contactPage.pageTitle')}
					<CrocodileRight />
				</SmallPageSectionRepulsiveRow>
			</StyledLink>
			{/*<SmallPageSectionDivider />*/}
			{user.isHost ? (
				toolsHeader ? (
					<>
						{/*<Link onClick={closeMenuOnLinkClick} to={routes.course.startPage.path}>
						<SmallPageSectionRepulsiveRow>
							<Markdown>{t('shared:userMenu.marketplace')}</Markdown>
							<In />
						</SmallPageSectionRepulsiveRow>
					</Link>*/}
					</>
				) : (
					<>
						<SmallPageSectionDivider />
						{/* Use a-tag instead of Link-tag, so that the manifest is loaded when they enter admin */}
						<a href={routes.admin.host.courses.url}>
							<SmallPageSectionRepulsiveRow>
								{t('shared:userMenu.backToTools')}
								<Out />
							</SmallPageSectionRepulsiveRow>
						</a>
					</>
				)
			) : (
				<>
					{/*<Link onClick={closeMenuOnLinkClick} to={routes.signup.tools.path}>
						<SmallPageSectionRepulsiveRow>
							<Text textColor="gold">{t('shared:userMenu.tools')}</Text>
							<CrocodileRight />
						</SmallPageSectionRepulsiveRow>
					</Link>*/}
				</>
			)}
			{adminSideBar || (
				<PageSectionRepulsiveRow>
					<a style={{ cursor: 'pointer' }} onClick={() => logOut(userState.pwa.openedInPwa)}>
						<Button color="OUTLINED_BLACK">{t('shared:userMenu.signOut')}</Button>
					</a>
					<div>
						<LanguageSwitcher color={theme.colors.black} />
					</div>
				</PageSectionRepulsiveRow>
			)}
			{user.isHost && <PwaButton adminSideBar={adminSideBar} t={t} />}
		</Wrapper>
	)
}

export default UserMenuContent
