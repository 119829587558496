import React, { useContext, useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import useRoutes from '../../../hooks/useRoutes'
import { theme } from '../../../utils/theme'
import UserContext from '../../../contexts/user/UserContext'

import { PageSidePaddingWrapper } from '../../reusable_components/layout/PageComponents'
import UserMenu from './UserMenu'
// import Search from '../../reusable_components/svg_icons/Search'
// import SearchContainer from '../../reusable_components/search/SearchContainer'
// import Breakpoint from '../../reusable_components/responsive/Breakpoint'
import { getAdminDestination, isServerRendering } from '../../../utils/applicationHelper'
// import { Link } from 'react-router-dom'
// import { P4 } from '../../reusable_components/Typography'
// import AlertBanner from './AlertBanner'
import { getCloudinaryImageUrl } from '../../../utils/imageHelper'
import EjblaLogo from '../../reusable_components/svg_icons/EjblaLogo'
import { Link } from 'react-router-dom'
import Breakpoint from '../../reusable_components/responsive/Breakpoint'
import GetStartedButton from '../../static_pages/ejbla_pro_page/GetStartedButton'
import { TextSpan } from '../../reusable_components/Typography'
import BackAndForwardButtons from './BackAndForwardButtons'
// import EjblaLogoHostAdmin from '../../reusable_components/svg_icons/EjblaLogoHostAdmin'

const HeaderWrapper = styled.div`
	position: fixed;
	left: 0;
	right: 0;
	top: 0;
	z-index: 7;
	display: ${({ hideHeader }) => (hideHeader ? `none` : 'block')};
	box-shadow: ${({ shadowHeader }) => (shadowHeader ? `0 -20px 25px ${theme.colors.darkGray}` : 'none')};
	> div:first-child {
		position: relative;
		z-index: 3;
	}
	> div:last-child {
		position: absolute;
		z-index: 2;
		background: white;
		opacity: ${({ transparentHeader }) => (transparentHeader ? 0 : 1)};
		transition: opacity 0.3s;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
`
export const WhiteLabelIconWrapper = styled.img`
	max-width: 200px;
	max-height: 48px;
`
const HeaderContent = styled.div`
	padding-top: 10px;
	padding-bottom: 10px;
	display: flex;
	> div {
		display: flex;
		align-items: center;
		:first-child {
			width: 40%;
			> div > svg {
				margin-left: ${theme.margins.small};
				${theme.breakpoints.phone.range} {
					margin-left: ${theme.margins.XSmall};
				}
			}
		}
		/*:nth-child(2) {
			justify-content: center;
			${theme.breakpoints.phone.range} {
				a {
					width: 100px;
				}
			}
		}*/
		:last-child {
			width: 60%;
			justify-content: flex-end;
			[data-header-item] {
				margin-right: ${theme.margins.large};
			}
		}
	}
`
/*const HamburgerMenu = styled.div`
	border-top: 1px solid ${theme.colors.black};
	border-bottom: 1px solid ${theme.colors.black};
	width: 40px;
	height: 10px;
	cursor: pointer;
`*/

const Header = ({
	categoryLinks,
	collectionLinks,
	t,
	toolsHeader,
	whiteLabelIcon,
	staticPage,
	fillCurrentPageToTop,
}) => {
	const [menuSidebarIsOpen, setMenuSidebarIsOpen] = useState(false)
	const routes = useRoutes()
	const [searchIsOpen, setSearchIsOpen] = useState(false)
	const [scrolledToTop, setScrolledToTop] = useState(true)
	const scrollableElement = isServerRendering() ? null : document.getElementsByTagName('html')[0]
	const searchIconRef = useRef(null)
	const { userState, dispatch } = useContext(UserContext)
	const user = userState.user
	const scrollListener = () => {
		// Switch out the black underline in header for a shadow when not scrolled to top
		if (scrollableElement.scrollTop === 0) {
			if (!scrolledToTop) {
				setScrolledToTop(true)
			}
		} else if (scrolledToTop) {
			setScrolledToTop(false)
		}
	}
	useEffect(() => {
		scrollListener()
		window.addEventListener('scroll', scrollListener)
		return () => {
			window.removeEventListener('scroll', scrollListener)
		}
	}, [scrolledToTop])
	const transparentHeader = fillCurrentPageToTop && scrolledToTop
	const shadowHeader = !scrolledToTop
	return (
		<>
			<HeaderWrapper
				id="HEADER"
				hideHeader={!!getAdminDestination()}
				shadowHeader={shadowHeader}
				transparentHeader={transparentHeader}
			>
				<div>
					<PageSidePaddingWrapper>
						<HeaderContent>
							{/*<div>
								{toolsHeader ? (
									<EjblaLogo />
								) : (
									!!whiteLabelIcon || (
										<>
											<HamburgerMenu
												onClick={event => {
													setMenuSidebarIsOpen(true)
												}}
											/>
											<div ref={searchIconRef}>
												<Search
													onClick={event => {
														setSearchIsOpen(!searchIsOpen)
													}}
												/>
											</div>
										</>
									)
								)}
							</div>*/}
							<div>
								{toolsHeader ? (
									<EjblaLogo />
								) : whiteLabelIcon ? (
									<WhiteLabelIconWrapper src={getCloudinaryImageUrl(whiteLabelIcon, 200)} />
								) : (
									<EjblaLogo fillColor={transparentHeader ? 'white' : null} />
								)}
								{userState.pwa.openedInPwa && (
									<Breakpoint breakpoint={theme.breakpoints.tablet.overAndExcluding}>
										<BackAndForwardButtons />
									</Breakpoint>
								)}
							</div>
							<div>
								{/*{toolsHeader || !!whiteLabelIcon || (
									<Breakpoint breakpoint={theme.breakpoints.tablet.overAndExcluding}>
										{user.isHost ? (
											<Link to={routes.course.index.path}>{t('copy:footer.workshops')}</Link>
										) : (
											<a href="https://tools.ejbla.com/" target="_blank">
												<P4 textColor="gold">{t('common:buttons.forOrganizers')}</P4>
											</a>
										)}
									</Breakpoint>
								)}*/}
								{staticPage && (
									<>
										<Breakpoint breakpoint={theme.breakpoints.tablet.overAndExcluding}>
											<Link to={routes.static.pricing.path} data-header-item>
												<TextSpan textColor={transparentHeader ? 'white' : 'textColor'}>
													{t('copy:pricingPage.pageTitle')}
												</TextSpan>
											</Link>
										</Breakpoint>
										<Breakpoint breakpoint={theme.breakpoints.tablet.overAndExcluding}>
											<GetStartedButton tier="no_tier" color={transparentHeader ? 'BLACK' : 'OUTLINED_BLACK'}>
												{t('common:buttons.hostEvents')}
											</GetStartedButton>
										</Breakpoint>
									</>
								)}
								{user.signedIn ? (
									<UserMenu
										user={user}
										t={t}
										toolsHeader={toolsHeader}
										transparentHeader={transparentHeader}
										whiteLabelIcon={whiteLabelIcon}
									/>
								) : (
									<div
										style={{ cursor: 'pointer', color: theme.colors[transparentHeader ? 'white' : 'textColor'] }}
										onClick={() =>
											dispatch({
												type: 'SESSION_MODAL_DISPLAY_STATE',
												data: { displayState: 'LOG_IN', initiateOnboarding: true },
											})
										}
									>
										{t('shared:mainMenu.signIn')}
									</div>
								)}
							</div>
						</HeaderContent>
						{/*{scrolledToTop && !searchIsOpen && <Divider />}*/}
						{/*{toolsHeader || (
							<SearchContainer
								searchIsOpen={searchIsOpen}
								setSearchIsOpen={setSearchIsOpen}
								searchIconRef={searchIconRef}
								t={t}
							/>
						)}*/}
					</PageSidePaddingWrapper>
				</div>
				<div /> {/* Empty div used by HeaderWrapper for CSS purposes */}
				{/*{!whiteLabelIcon && <AlertBanner />}*/}
			</HeaderWrapper>
			{/*{toolsHeader || (
				<SideBar isOpen={menuSidebarIsOpen} handleClose={() => setMenuSidebarIsOpen(false)} maxWidth={500}>
					<LargePaddingWrapper>
						<HeaderSideBar
							setSidebarIsOpen={setMenuSidebarIsOpen}
							categoryLinks={categoryLinks}
							collectionLinks={collectionLinks}
							t={t}
							user={user}
						/>
					</LargePaddingWrapper>
				</SideBar>
			)}*/}
		</>
	)
}

export default Header
